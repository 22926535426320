.infoTable {
  border: 2px solid #A182A4;
  min-height: 425px;
}

.infoTable .rt-th {
  background-color: #A182A4;
  font-weight: bold;
  font-size: 100%;
  position: relative;
  overflow: visible;
  color: #fff;
  text-shadow: 1px 1px 0 #000,    -1px 1px 0 #000,    -1px -1px 0 #000,    1px -1px 0 #000;
}

.wm__btn-outline-info {
  color: #A182A4;
  border-color: #A182A4;
  background-color: white;
}

.wm__btn-outline-info:hover {
  color: white;
  background-color: #A182A4;
  border-color: #A182A4;
}

.wm__margin_right {
  color: #A182A4;
  border-color: #A182A4;
  background-color: white;
}

.wm__margin_right:hover {
  color: white;
  background-color: #A182A4;
  border-color: #A182A4;
}
